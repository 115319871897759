// dependencies
import React from "react";
import moment from 'moment-timezone';
import createReactClass from 'create-react-class';
import FooterDateTime from './footer_date_time';
import Header from './header';
import Sessions from './sessions';
import TimeGroupedSessions from './time_grouped_sessions';
import Splitflap from './splitflap'
// const DatePicker = require('react-datepicker');
import Footer from './footer';
import Map from "./map"
import { Autocomplete, TextField } from "@mui/material";
import Select from 'react-select';
const Agenda = createReactClass({
  getInitialState() {
    const {early_filter_time, fast_forward_date, session_length_filter, timezone} = this.props.settings;
    const dateNums = (fast_forward_date||"2024-06-03").split("-");
    const startDateYear = Number(dateNums[0]);
    const startDateMonth = Number(dateNums[1]);
    const startDateDay = Number(dateNums[2]);
    return {
      background: '#000000',
      sessionData: {},
      sessions: '',
      clock: this.clockTick(),
      hasPolled: false,
      overrideDate: this.getParam('date'),
      overrideTime: this.getParam('time'),
      earlyFilterTime: early_filter_time,
      startDateYear: startDateYear,
      startDateMonth: startDateMonth - 1, // zero-indexed
      startDateDay: startDateDay,
      sessionLengthFilter: session_length_filter,
      timezone: timezone,
      maps: [],
      signageLocations: [],
      rooms: [],
      currentSignageLocationId: this.getParam('signage_location')
    };
  },
  

  setOverrideDate(e){
    this.setState({
      overrideDate: e.target.value
    }, this.clockUpdate)
  },

  setOverrideTime(e){
    this.setState({
      overrideTime: e.target.value
    }, this.clockUpdate)
  },


  renderTimemachine() {
    if(this.getParam('timemachine') !== ""){
      return (
        <div className="agenda-time-machine">
          <div className="time-machine-title">Time Machine:</div>
          <label>Date </label>
          <input value={this.state.overrideDate} onChange={this.setOverrideDate} type="date" className="form-control" />
          <br />
          <label>Time: </label>
          <input type="time" value={this.state.overrideTime} onChange={this.setOverrideTime} className="form-control" />
          <label> Signage Location</label>
          {this.renderTimeMachineLocations()}
        </div>
      );
    }
  },

  renderTimeMachineLocations() {
    const { signageLocations, currentSignageLocationId } = this.state;
    const signageLocation = (signageLocations||[]).find(x=> x.id == currentSignageLocationId) || {};
    
    return (
      <Select 
        clearable
        value={{
          value: signageLocation.id,
          label: signageLocation.name
        }}
        options={
          (signageLocations||[]).map(x => ({ value: x.id, label: x.name }))
        }
        onChange={(value) => {
          this.setState({currentSignageLocationId: value ? value.value : null});
        }}
      />
    )
  },

  startPoll() {
    const self = this;
    setTimeout(() => {
      self.pollData();
      if (!self.isMounted()) { return; }
      self._timer = setInterval(self.pollData, 60000);
    }, 1000);
  },

  pollData() {
    if (!this.isMounted()) {
      return;
    }

    const self = this;
    console.log('polling backend');
    $.ajax({
      url: self.props.dataUrl,
      type: 'GET',
      data: {},
      dataType: 'json',
      success(result, status, _xhr) {
        localStorage.setItem('sessions', JSON.stringify(result.sessions));
        if (self.isMounted()) {
          self.setState({
            sessionData: result.sessions,
            hasPolled: true,
            maps: result.maps,
            signageLocations: result.signage_locations,
            rooms: result.rooms
          });
        }
      },
      error(xhr, status, error) {
        console.log('Unable to load session data:');
        console.log(status);
        console.log(error);
        console.log(xhr);
      }
    });
  },

  clockUpdate() {
    console.log("Updating clock");
    this.setState({
      clock: this.clockTick()
    });
  },

  clockTick() {
    let override = ""
    let overrideTime = null
    let timezone = "America/Los_Angeles" //default value while loading
    let startDateYear = null;
    let startDateMonth = null;
    let startDateDay = null;
    if(this.state){
      override = this.state.overrideDate;
      overrideTime = this.state.overrideTime;
      timezone = this.state.timezone;
      startDateYear = this.state.startDateYear;
      startDateMonth = this.state.startDateMonth;
      startDateDay = this.state.startDateDay;
    }
    if (override && override !== "") {
      let hour = 8;
      let minute = 0;
      if(overrideTime){
        const timeElems = overrideTime.split(":");
        hour =  parseInt(timeElems[0]);
        minute = parseInt(timeElems[1]);
      }
      const dateElems = override.split("-");
      const year = parseInt(dateElems[0]);
      const month = parseInt(dateElems[1]) - 1;
      const day = parseInt(dateElems[2]);
      const overrideDate = moment().set({
        year, month, date: day, hour: hour, minute: minute
      }).tz(timezone, true);

      return overrideDate;
    }
    const now = moment().tz(timezone);
    // Remember, month is 0-indexed even in momentjs
    const firstDay = moment().tz(timezone).set({ year: startDateYear, month: startDateMonth, date: startDateDay });
    if (now.isBefore(firstDay, 'date')) {
      return firstDay;
    }
    return now;
  },

  startClock() {
    const self = this;
    setTimeout(() => {
      self.clockUpdate();
      if (!self.isMounted()) { return; }
      self._timer = setInterval(self.clockUpdate, 10000);
    }, 1000);
  },

  getParam(sname) {
    let params = location.search.substr(location.search.indexOf("?") + 1);
    let sval = "";
    params = params.split("&");
    // split param and value into individual pieces
    for (let i = 0; i < params.length; i++) {
      const temp = params[i].split("=");
      if ([temp[0]] == sname) { sval = temp[1]; }
    }
    return sval;
  },


  componentDidMount() {
    this.startPoll();
    this.startClock();
    this.state.background = localStorage.background;
  },

  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  },

  filteredSessions() {
    const self = this;
    const now = self.state.clock;
    const {earlyFilterTime, maxSessionLength, timezone} = self.state;
    const zone = moment.tz.zone(timezone);
    const offset = zone.parse(Date.UTC(now._d))

    const filtered = this.state.sessionData.filter( (session, i) => {
      if(!session.start_time) {
        return false
      }
      session.start_time_fixed = session.start_time.split(' ')[1]
      const sessionStart = moment(session.date + " " + session.start_time_fixed + " ", 'YYYY-M-D HH:mm Z').tz(timezone, true);
      
      let sessionEnd = sessionStart;
      if(session.end_time) {
        session.end_time_fixed = session.end_time.split(' ')[1];
        sessionEnd = moment(session.date + " " + session.end_time_fixed + " ", 'YYYY-M-D HH:mm Z').subtract(earlyFilterTime, "m").tz(timezone, true);
      }

      // first filter out sessions that aren't on this day
      if (!sessionStart.isSame(now, 'day')) {
        return false;
      }
      // next filter out sessions that have already concluded
      if (sessionEnd.isBefore(now, 'minute')) {
        return false;
      }

      // filter out anything whose length exceeds maxSessionLength
      if (parseInt(session.length) > maxSessionLength) {
        return false;
      }


      const fifteenMinutes = 15*60*1000
      const oneSecond = 1000
      if(sessionStart.isBefore(now+fifteenMinutes+oneSecond, 'second')) {
        session.current = true
      }else{
        session.current = false
      }
      

      // anything that's left is good
      return true;
    });

    return filtered;
  },

  classNames() {
    return "agenda";
  },

  renderSchedule(sessions) {
    const signageLocations = this.state.signageLocations.filter(x=> x.id == this.state.currentSignageLocationId);
    switch(this.props.type) {
      case "splitflap":
        return (<Splitflap sessions={sessions} rooms={this.state.rooms}/>)
      default:
        return (
          <>
            <div className="main-flexbox">
              <div className="agenda-maps">
                <Map id={1} signageLocations={signageLocations} maps={this.state.maps} sessions={sessions} rooms={this.state.rooms} />
                <Map id={2} signageLocations={signageLocations} maps={this.state.maps} sessions={sessions} rooms={this.state.rooms} />
              </div>
              <div className="agenda-sessions">
                <div className="agenda-sessions-header">
                  Agenda
                </div>
                <div className="agenda-sessions-sub-header">
                  {this.state.clock.format('dddd, MMMM D, YYYY').replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')}
                </div>
                <Sessions sessions={sessions} rooms={this.state.rooms} />
              </div>

            </div>
            <FooterDateTime clock={this.state.clock} />
          </>
        )
    }
  },

  render() {
    let sessions = []
    if(!this.state.hasPolled) {
      return <div> 
        Loading
      </div>
    }

    if(this.state.hasPolled && (Object.keys(this.state.sessionData).length !== 0)) {
      sessions = this.filteredSessions();
    }


// <Sessions sessions={sessions} />
    return (
      <div>
        {this.renderTimemachine()}
        {this.renderSchedule(sessions)}
      </div>
    );
  }
});

export default Agenda;