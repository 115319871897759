var React = require("react");
var createReactClass = require('create-react-class');
// var FooterDate = require('components/footer_date');
// var FooterTime = require('components/footer_time');
var FooterDateTime = require('components/footer_date_time');
var DarkskyWeather = require('components/darksky_weather');

var Footer = createReactClass({
  // not used for GSX18, black footer

  render: function() {
    return (
      <div id="footer-container">
        <div id="footer">
          <div className="footer-flex">
            <div className="footer-flex-child">
              <FooterDateTime clock={this.props.clock} />
            </div>
            <div className="footer-flex-child">
              <div className="vertical-bar" />
            </div>
            <div className="footer-flex-child-2">
              <DarkskyWeather />
            </div>
            <div className="footer-flex-child">
              <div className="vertical-bar" />
            </div>
            <div className="footer-flex-child">
              <div className="footer-float">
                <img className="cisco-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = Footer
