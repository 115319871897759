import React, { useEffect } from "react";
import Session from './session';
import { addMinutes, subMinutes } from "date-fns";
const Sessions = ({ sessions, rooms }) => {
  const [ scrollCounter, setScrollCounter ] = React.useState(0)
  const [ sortedSessions, setSortedSessions ] = React.useState([])

  useEffect(() => {
    setSortedSessions(getSortedSessions())
    if(sortedSessions.filter(x=>x.current).length < 14 && sortedSessions.length > 0) {
      
      document.getElementById("session-" + sortedSessions[0].id).scrollIntoView({
        behavior: 'smooth', block: 'start', inline: 'nearest'
      })
    }
  }, [sessions])

  const setScrollTimer = async () => {
    setScrollCounter(scrollCounter + 1)
    const currentSessions = sortedSessions.filter(x=> x.current);
    if (currentSessions.length > 14) {
      const beginningOrEnd = scrollCounter % 2 === 0 ? "beginning" : "end";
      let scrollId = currentSessions[beginningOrEnd === "beginning" ? 0 : currentSessions.length - 1].id
      
      if(beginningOrEnd == "end"){
        index = sortedSessions.findIndex((x)=> x.id == scrollId)
        if(index < sortedSessions.length - 1){
          scrollId = sortedSessions[index+1].id
        }
      }

      document.getElementById("session-" + scrollId).scrollIntoView({
        behavior: 'smooth', block: beginningOrEnd === "beginning" ? 'start' : 'end', inline: 'nearest'
      });
    }else if(currentSessions.length > 0){
      document.getElementById("session-" + currentSessions[0].id).scrollIntoView({
        behavior: 'smooth', block: 'start', inline: 'nearest'
      })
    }
  }

  useInterval(setScrollTimer, 10000);

  const getSortedSessions = () => {
    var sessionCollect = [];
    if (sessions !== undefined) {
      sessionCollect = sessionCollect.concat(sessions).sort((a,b)=>{
        let dateA = new Date(a.start_datetime) 
        let dateB = new Date(b.start_datetime)
  
  
        if(a.name == "Registration"){
          dateA = subMinutes(dateA,3);
        }
        if(b.name == "Registration"){
          dateB = subMinutes(dateB,3);
        }
  
        if(a.name!= "Registration" && b.name!= "Registration" && dateA.getTime() == dateB.getTime() && a.end_datetime && b.end_datetime){
          const dateAEnd = new Date(a.end_datetime)
          const dateBEnd = new Date(b.end_datetime)
          return dateAEnd < dateBEnd ? -1 : (dateAEnd > dateBEnd ? 1 : 0); 
        }
        return dateA < dateB ? -10000 : (dateA > dateB ? 10000 : 0);
      })
    }
    return sessionCollect
  }

  if (sortedSessions.length === 0) {
    return <div />
  }
  else {
    return (
      <div className="sg-sessions">
        {sortedSessions.map((session, i) => {
          return <Session rooms={rooms} key={session.id} session={session} />
        })}
      </div>
    );
  }
}

export default Sessions;


function useInterval(callback, delay) {
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}

