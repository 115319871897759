
import React from "react";

const Map = ({
  id, 
  maps,
  sessions,
  rooms,
  signageLocations
}) => {
  const map = maps.find(x => x.render_order == id);
  if(!map) {
    return (
      <div className="sg-agenda-map">
        Map {id} not found
      </div>
    )
  }

  const renderRooms = () => {
    const currentSessions = (sessions||[]).filter(session => session.current);
    const currentSessionRoomIds = currentSessions.map(session => `${session.room_id}`);
    const currentRooms = rooms.filter(x=> currentSessionRoomIds.indexOf(x.room_id) != -1).filter(x=> x.overlay_image_url).filter(x=>x.map_id == map.id)
    
    return currentRooms.map(room => {
      return (
        <img key={room.id} src={
          room.overlay_image_url
        }/>
      );
    })
  }


  const renderYouAreHere = () => {
    if(signageLocations) {
      const signageLocation = (signageLocations||[]).find(x => x.map_id == map.id);
      if(signageLocation) {
        return (
          <img className="" src={
            signageLocation.overlay_image_url
          }/>
        )
      }
    }
  }

  return (
    <div className="sg-agenda-map">
      <div className="sg-agenda-map-description">
        {map.description}
      </div>
      <img src={
        map.image_url
      }/>
      {renderRooms()}
      {renderYouAreHere()}
    </div>
  )

};

export default Map;