import React from "react";
import { FlapDisplay, Presets } from 'react-split-flap-effect'
import moment from 'moment-timezone';

const Splitflap = (props) => {
  const { sessions, rooms } = props;

  const getSortedSessions = () => {
    if (sessions) {
      return sessions.sort((a, b) => {
        return new Date(a.start_datetime) - new Date(b.start_datetime)
      }).filter(x=>!x.expired);
    }
    return []
  }

  const convertSessionsToRows = () => {
    const suppliedSessions = getSortedSessions();
    let row = 0;
    let rows = [];
    let currentSessionIndex = 0
    while (rows.length < maxRows) {
      if(suppliedSessions.length > row) {
        const session = suppliedSessions[currentSessionIndex]
        if(session) {
          groupArrayOfWordsByMaxLetterCount(session.name.split(" "), 29).forEach((name,index)=> {
            if(index == 0) {
              rows.push({
                room: rooms.find(x=> `${x.room_id}` == `${session.room_id}`),
                session: session,
                startTime: session.start_datetime ? moment.utc(session.start_datetime.replaceAll("-07:00", "").replaceAll("-08:00", "")) : null,
                name: name
              })
            }else {
              rows.push({name: name})
            }
          })
        }else {
          rows.push({})
        }
      }else {
        rows.push({})
      }
      currentSessionIndex +=1;
    }
    return rows

  }


  const groupArrayOfWordsByMaxLetterCount = (arrayOfWords, maxLetterCount) => {
    let groupedArray = [""];
    let currentLetterCount = 0
    arrayOfWords.forEach(word=> {
      const currentItem = groupedArray[groupedArray.length - 1]
      if(currentItem.length == 0 || currentItem.length + word.length < maxLetterCount) {
        groupedArray[groupedArray.length - 1] = currentItem +word+" "
      }else {
        groupedArray.push(word+" ")
      }
    })
    return groupedArray
  }

  const renderSessions = () => {
    const suppliedSessions = getSortedSessions()
    if (sessions) {
      return convertSessionsToRows().map((x,i)=> renderSessionFromObject(x,i));
      //return [...Array(maxRows).keys()].map((index)=> renderSession( index, suppliedSessions ));
    }
  }

  const splitFlapProps = {className: 'splitflap'}
  const maxRows = 14

  const renderSessionFromObject = (sessionObject,index) => {
    const { room, session, startTime, name } = sessionObject
    console.log({index});
    if(index >= maxRows) {
      return;
    }
    return (
      <tr key={`splitflap-row-${index}`}>
      <td>
        <FlapDisplay
          {...splitFlapProps}
          chars={'0123456789apm: '}
          length={8}
          value={
            startTime ? startTime.format('hh:mma') : ""
          }
        />
      </td>
      <td>
        <FlapDisplay
         {...splitFlapProps}
          chars={'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 '}
          length={30}
          value={name||""}
        />
      </td>
      <td>
        <FlapDisplay
          {...splitFlapProps}
          chars={'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 '}
          length={15}
          value={(room||{}).name || ""}
        />
      </td>
    </tr>
    )
  }

  renderSession = (index, suppliedSessions) => {
    let session = {}
    if(index < suppliedSessions.length) {
      session = suppliedSessions[index]||{} 
    };

    const room = rooms.find(x=> `${x.room_id}` == `${session.room_id}`)
    var startTime = session.start_datetime ? moment.utc(session.start_datetime.replaceAll("-07:00", "").replaceAll("-08:00", "")) : null;
    
    return (
      <tr key={`splitflap-row-${index}`}>
      <td>
        <FlapDisplay
          {...splitFlapProps}
          chars={'0123456789apm: '}
          length={8}
          value={
            startTime ? startTime.format('hh:mm a') : ""
          }
        />
      </td>
      <td>
        <FlapDisplay
         {...splitFlapProps}
          chars={Presets.ALPHANUM}
          length={30}
          value={(session||{}).name || ""}
        />
      </td>
      <td>
        <FlapDisplay
          {...splitFlapProps}
          chars={Presets.ALPHANUM}
          length={15}
          value={(room||{}).name || ""}
        />
      </td>
    </tr>
    )
  }

  return (
    <div className="sg-splitflap-container">
      <div className="sg-splitflap-header">
        Upcoming Sessions
      </div>
  
      <table className="sg-splitflap-table"> 
        <thead>
          <tr>
            <th>Time</th>
            <th>Session</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {renderSessions()}
        </tbody>
      </table>
    </div>
  )
};

export default Splitflap