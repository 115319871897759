import React, {
  useState,
  useEffect
} from "react";
import DataGridTableToolbar from "./DataGridTableToolbar";
import { LicenseInfo } from '@mui/x-license-pro'
import { 
  Button,
  TextField,
  Autocomplete,
  Link
} from "@mui/material";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import Cookies from "universal-cookie";
import RoomPhotoDropzone from "./RoomPhotoDropzone";
import MapPhotoDropzone from "./MapPhotoDropzone";
import Switch from '@mui/material/Switch';
import SignageLocationPhotoDropzone from "./SignageLocationPhotoDropzone";
import { BlockPicker } from 'react-color';

const MapDashboard = ({ }) => {

  const MUI_KEY = "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
  LicenseInfo.setLicenseKey(MUI_KEY);
  const [ rooms, setRooms ] = React.useState([]);
  const [ maps, setMaps ] = React.useState([]);
  const [ signageLocations, setSignageLocations ] = React.useState([]);
  const [ token, setToken ] = useState(new Cookies().get("X-CSRF-Token"));
  const syncRooms = () => {
    fetch('/admin/rooms/sync').then(
      (response) => response.json()
    ).then(fetchRooms); 
  }
  
  const destroyAllRooms = () => {
    if(confirm("Are you sure you want to delete all rooms? This action cannot be undone")) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch('/admin/rooms/destroy_all', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        }
      }).then(
        //Reload window
       // window.location.reload(true)
      )
    }
  }

  const createMap = async () => {
    await fetch('/admin/maps', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        map: {
          name: "New Map"
        }
      })
    })

    fetchMaps();
  }

  const createSignageLocation = async () => {
    await fetch('/admin/signage_locations', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        signage_location: {
          name: "New Signage Location"
        }
      })
    })

    fetchSignageLocations();
  }

  const fetchRooms = () => {
    fetch('/admin/rooms')
      .then((response) => response.json())
      .then((data) => setRooms(data.sort((a, b) => a.id - b.id)));
  }
  const slots = {
    toolbar: DataGridTableToolbar
  };

  const fetchSignageLocations = () => {
    fetch('/admin/signage_locations')
      .then((response) => response.json())
      .then((data) => setSignageLocations(data.sort((a, b) => a.id - b.id)));
  }

  const fetchMaps = () => {
    fetch('/admin/maps')
      .then((response) => response.json())
      .then((data) => setMaps(data.sort((a, b) => a.id - b.id)));
  }

  useEffect(() => {
    fetchSignageLocations();
    fetchRooms();
    fetchMaps();
  }, []);

  const updateMap = async (id, newFields) => {
    const indexOfItem = maps.map(x=>x.id).indexOf(id);
    const item = maps[indexOfItem];
    Object.keys(newFields).forEach((key) => {
      item[key] = newFields[key];
    })
    const newMaps = [...maps];
    newMaps[indexOfItem] = item;
  }

  const updateSignageLocation = async (id, newFields) => {
    const indexOfItem = signageLocations.map(x=>x.id).indexOf(id);
    const item = signageLocations[indexOfItem];
    Object.keys(newFields).forEach((key) => {
      item[key] = newFields[key];
    })
    const newSignageLocations = [...signageLocations];
    signageLocations[indexOfItem] = item;
  }

  const updateRoom = async (id, newFields) => {
    console.log(newFields)
    const indexOfItem = rooms.map(x=>x.id).indexOf(id);
    const item = rooms[indexOfItem];
    Object.keys(newFields).forEach((key) => {
      item[key] = newFields[key];
    })
    const newRooms = [...rooms];
    newRooms[indexOfItem] = item;
    setRooms(newRooms)
  }

  const saveMap = (params, event) => {
    if(!(event.target)) return
    const id = params.id
    const indexOfItem = maps.map(x=>x.id).indexOf(id);
    const item = maps[indexOfItem];
    item[params.field] = event.target.value;
    maps[item] = item
    setMaps(
      maps
    )

    const newMapUpdate = {}
    newMapUpdate[params.field] = event.target.value
    fetch(`/admin/maps/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        map: newMapUpdate
      })
    })
  }

  const saveSignageLocationFromTable = (params, event) => {
    if(!(event.target)) return
    saveSignageLocation(params.id, {[params.field]: event.target.value})
  }
  
  const saveRoom = async (id, newFields) => {
    
    const indexOfItem = rooms.map(x=>x.id).indexOf(id);
    let item = rooms[indexOfItem];
    item = {...item, ...newFields}
    rooms[indexOfItem] = item
    setRooms(
      rooms.filter(x=> x.id !== id).concat(item).sort((a, b) => a.id - b.id)
    )
    fetch(`/admin/rooms/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        room: item
      })
    })
  }

  const saveSignageLocation = async (id, newFields) => {
    const indexOfItem = signageLocations.map(x=>x.id).indexOf(id);
    let item = signageLocations[indexOfItem];
    item = {...item, ...newFields}
    signageLocations[indexOfItem] = item
    setSignageLocations(
      signageLocations.filter(x=> x.id !== id).concat(item).sort((a, b) => a.id - b.id)
    )
    fetch(`/admin/signage_locations/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        signage_location: item
      })
    })
  }


  const signageLocationsColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', editable: true, flex: 1 },
    { field: 'map_id', headerName: 'Map ID', flex: 1, renderCell: (params) => {
      const map = maps.find(x => x.id === params.value)
      const value = map ? {
        label: map.name,
        id: map.id
      } : null
      return (
        <Autocomplete
          options={maps.map(x=>{
            return {label: x.name, id: x.id}
          })}
          onChange={(event, newValue) => {
            saveSignageLocation(params.id, {map_id: newValue ? newValue.id : null})
          }}
          value={value}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Map" />}
        />
      )
    }},

    {
      field: "image",
      headerName: "Image",
      editable: true, 
      renderCell: (params) => (
        <img style={{width:"50px", height:"50px", objectFit:"contain"}} src={params.row.overlay_image_url} />
      ),
      renderEditCell: (params) => (
        <SignageLocationPhotoDropzone 
          key={`signage-location-${params.id}`}
          signage_location={params.row}
          callback={(overlay_image_url)=>{
            updateSignageLocation(params.id, {overlay_image_url})
          }}
        />
      )
    },
    { field: "actions", flex: 1, headerName: "Actions", renderCell: (params) => (
      <>
        <Button href={`/?signage_location=${params.row.id}`}>Link</Button>
        <Button href={`/?timemachine=true&signage_location=${params.row.id}`}>Time Machine</Button>
      </>
    )}
  ]

  const mapColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', editable: true, flex: 1 },
    { field: 'description', headerName: 'Description', editable: true, flex: 1 },
    {
      field: "image",
      headerName: "Image",
      editable: true, 
      renderCell: (params) => (
        <img style={{width:"50px", height:"50px", objectFit:"contain"}} src={params.row.image_url} />
      ),
      renderEditCell: (params) => (
        <MapPhotoDropzone 
          key={`map-${params.id}`}
          map={params.row}
          callback={(image_url)=>{
            updateMap(params.id, {image_url})
          }}
        />
      )
    },
    {field: "render_order", flex: 1, headerName: "Render Order", 
      renderCell: (params) => {
        const value = renderOrders.find(x => x.id === params.value)

        return (
          <Autocomplete
            options={renderOrders}
            onChange={(event, newValue) => {
              saveMap({id: params.id, field: "render_order"}, {target: {value: newValue ? newValue.id : null}})
            }}
            value={value}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Map" />}
          />
        )
      }
    },
  ]

  const renderOrders = [
    {label:"Do not render", id: 0},
    {label:"Top", id: 1},
    {label:"Bottom", id: 2},
  ]

  const roomColumns = [
    { field: 'id', flex: 1, headerName: 'ID', hide: true },
    { field: 'name',flex: 1, headerName: 'Name'},
    { field: 'hidden',flex: 1,hidden: true, hide: true, headerName: 'Hidden', type: 'boolean', renderCell: (params) => <Switch checked={params.value} onChange={(e) => saveRoom(params.id, {hidden: e.target.checked})} />},
    //Select Map
    {field: 'map_id',flex: 1, headerName: 'Map',
      renderCell: (params) => {
        const map = maps.find(x => x.id === params.value)
        const value = map ? {
          label: map.name,
          id: map.id
        } : null
        return (
          <Autocomplete
            options={maps.map(x=>{
              return {label: x.name, id: x.id}
            })}
            onChange={(event, newValue) => {
              saveRoom(params.id, {map_id: newValue ? newValue.id : null})
            }}
            value={value}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Map" />}
          />
        )
      },
    },
    { field: 'color', headerName: 'Color', editable: true, flex: 1,sx: {overflow: "visible"},
      renderCell: (params) => {
        const room = rooms.find(x => x.id === params.row.id)
        return (
          <div style={{background: room.color||"#ffffff", width:"30px",border: "1px solid #eee", height:"30px", borderRadius:"50%"}}></div>
        );
      },
    
      renderEditCell: (params) => {
        const room = rooms.find(x => x.id === params.row.id)
        return (
          <>
            <input type="color" value={room.color||"#ffffff"} onChange={(event)=>{
              saveRoom(params.id, {color: event.target.value})
            }} />
          </>
        )
      }
    },
    {
      field: "overlay_image",
      headerName: "Overlay Image",
      editable: true, 
      renderCell: (params) => (
        <img style={{width:"50px", height:"50px", objectFit:"contain"}} src={params.row.overlay_image_url} />
      ),
      renderEditCell: (params) => (
        <RoomPhotoDropzone 
          room={params.row}
          key={`room-${params.id}`}
          callback={(overlay_image_url)=>{
            updateRoom(params.id, {overlay_image_url})
          }}
        />
      )
    }
  ]


  return (
    <div> 

      <div className="row">
        <input />
        <div className="col-xs-12">
          <h1>Maps</h1>
          <Button
            variant="contained"
            onClick={createMap}
          >
            New Map
          </Button>
        </div>
        <div className="col-xs-12">
          <DataGridPremium
            onCellEditStop={saveMap}
            autoHeight
            unstable_headerFilters
            rows={maps}
            columns={mapColumns}
            slots={slots}
            checkboxSelection={true}
            style={{
              cell: {
                paddingBottom: 0,
                paddingRight: 0,
                whiteSpace: 'normal',
                overflow: 'visible',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },

            }}
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <h1>Rooms</h1>
        </div>
        <div className="col-xs-12">
          <Button
            variant="contained"
            onClick={syncRooms}
          > Sync Rooms (this may take a minute)</Button>
          <Button
            color="error"
            onClick={destroyAllRooms}
          > Destroy All (dangerous)</Button>
          <DataGridPremium
            autoHeight
            unstable_headerFilters
            rows={rooms}
            columns={roomColumns}
            slots={slots}
            checkboxSelection={true}
            style={{
              cell: {
                paddingBottom: 0,
                paddingRight: 0,
                whiteSpace: 'normal',
                overflow: 'visible',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  hidden: false,
                },
              },
              filter: {
                filterModel: {
                  items: [
                    {
                      field: 'hidden',
                      operator: 'is',
                      value: "false",
                    }
                  ]
                }
              }
            }}
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </div>
      </div>
        

      <div className="row">
        <div className="col-xs-12">
          <h1>Signage Locations</h1>
        </div>
        <div className="col-xs-12">
          <Button
            variant="contained"
            onClick={createSignageLocation}
          > Add Signage Locations
          </Button>

        </div>
        <div className="col-xs-12">
          <DataGridPremium
            onCellEditStop={saveSignageLocationFromTable}
            autoHeight
            unstable_headerFilters
            rows={signageLocations}
            columns={signageLocationsColumns}
            slots={slots}
            checkboxSelection={true}
            style={{
              cell: {
                paddingBottom: 0,
                paddingRight: 0,
                whiteSpace: 'normal',
                overflow: 'visible',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  hidden: false,
                },
              },
              filter: {
                filterModel: {
                  items: [
                    {
                      field: 'hidden',
                      operator: 'is',
                      value: "false",
                    }
                  ]
                }
              }
            }}
            pageSizeOptions={[5, 10, 20, 50]}
          />
        </div>
      </div>
    </div>
  )
}

export default MapDashboard;
