import React from "react";
import Dropzone from "react-dropzone";


const SignageLocationPhotoDropzone = ({
  signage_location,
  callback
}) => {

  const [previewImage, setPreviewImage] = React.useState(signage_location.overlay_image_url);

  const onDrop = (acceptedFiles) => {
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onloadend = () => {
      setPreviewImage(
        reader.result
      )
      callback(reader.result)
    }
    savePhoto(acceptedFiles[0]);
  }

  savePhoto = (file) => {
    console.log('saving photo');
    const formData = new FormData();
    formData.append('signage_location[overlay_image]', file);
  
    fetch(`/admin/signage_locations/${signage_location.id}`, {
      method: "PATCH",
      body: formData
    })
  }

  return (
    <Dropzone
      onDrop={onDrop}
      name={`signage_location[${signage_location.id}][overlay_image]`}
    >
      {({getRootProps, getInputProps}) => (
        <section>
          <div className="table-dropzone" {...getRootProps()}>
            {
              previewImage ? <img src={previewImage} style={{width:"50px",height:"50px", objectFit: "contain"}} /> : (
                <p>Drop File Here</p>
              )
            }
            <input name={`signage_location[${signage_location.id}][overlay_image]`} {...getInputProps()} />
            
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default SignageLocationPhotoDropzone