var React = require("react");
var createReactClass = require('create-react-class');

var DarkskyWeather = createReactClass({
  getInitialState: function() {
    return {
      tempF: 0,
      tempC: 0,
      condition: "Sunny",
      uvIndex: 0
    };
  },

  componentDidMount: function() {
    this.startPoll();
  },

  startPoll: function() {
    var self = this;

    setTimeout(function() {
      self.pollWeather();

      self._timer = setInterval(self.pollWeather, 600000);
    }, 1000);
  },

  weatherIcon: function() {
    switch(this.state.condition) {
      case "Sunny":
        return "wi wi-day-sunny";
        break;
      case "Mostly Cloudy":
        return "wi wi-day-cloudy";
        break;
      case "Partly Cloudy":
        return "wi wi-day-cloudy";
        break;
      case "Cloudy":
        return "wi wi-day-cloudy";
        break;
      case "Rain":
        return "wi wi-day-rain";
        break;
      case "Scattered Showers":
        return "wi wi-day-rain";
        break;
      case "Showers":
        return "wi wi-day-rain-mix";
        break;
      case "Windy":
        return "wi wi-day-cloudy-gusts";
        break;
      case "Snow":
        return "wi wi-day-snow";
        break;
      case "Thunderstorms":
        return "wi wi-day-thunderstorm";
        break;
      case "Scattered Thunderstorms":
        return "wi wi-day-thunderstorm";
        break;
      case "Foggy":
        return "wi wi-day-fog";
        break;
      default:
        return "wi wi-day-sunny";
    }
  },

  pollWeather: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: '/weather/darksky',
      dataType: 'json',
      success: function(data, status, xhr) {
        if (self.isMounted()) {
          var tempF = data.currently.temperature;
          var tempC = (tempF - 32) * (5.0/9);
          var condition = data.currently.summary;
          var uvIndex = data.currently.uvIndex;
          self.setState({
            tempF: tempF,
            tempC: tempC,
            condition: condition,
            uvIndex: uvIndex
          });
        }
      }
    })
  },

  render: function() {
    return (
      <>
        <div className="darksky-weather-icon">
          <i className={this.weatherIcon()} alt={this.state.condition}></i>
        </div>
        <div className="darksky-weather-temperature-c">
          {this.state.condition} | {Math.round(this.state.tempC)}°C / {Math.round(this.state.tempF)}°F
        </div>
      </>
    );
  }
});

module.exports = DarkskyWeather