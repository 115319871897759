import React from "react";
import moment from 'moment-timezone';
import createReactClass from 'create-react-class';

var Session = createReactClass({
  timeDisplay: function() {
    var startTime = moment.utc(this.removeTimezone(this.props.session.start_datetime)); //TODO Replace All

    
    // include end time
    if (this.props.session.end_time) {//http://localhost:7245/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhkVGs0WW1ONmVXYzBabWd3ZEdGa056RnZhRFI1Y25sdk0yUmlPUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpWDJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWsxQ1gweGxkbVZzTVY5eVpXZHBjM1J5WVhScGIyNHVjRzVuSWpzZ1ptbHNaVzVoYldVcVBWVlVSaTA0SnlkTlFsOU1aWFpsYkRGZmNtVm5hWE4wY21GMGFXOXVMbkJ1WndZN0JsUTZFV052Ym5SbGJuUmZkSGx3WlVraURtbHRZV2RsTDNCdVp3WTdCbFE2RVhObGNuWnBZMlZmYm1GdFpUb0tiRzlqWVd3PSIsImV4cCI6IjIwMjQtMDctMTJUMTk6MjA6MzIuOTYxWiIsInB1ciI6ImJsb2Jfa2V5In19--8b2957756f23235aed4d9e9265aaa1f91df75030/MB_Level1_registration.png
      var stopTime = moment.utc(this.removeTimezone(this.props.session.end_datetime)); //TODO Replace All
      // 12hr time
      return (startTime.format('h:mm a') + " \u2013 " + stopTime.format('h:mm a')).replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')
      // 24hr time
      // return startTime.format('HH:mm') + " \u2013 " + stopTime.format('HH:mm');
    }

    return (startTime.format('h:mm a')).replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.');
  },

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  },

  removeTimezone: function(str) {
    const string = str || "";
    return string.replace("-07:00", "").replace("-08:00", "");
  },

  getRoomName: function() {
    const room = this.props.rooms.find(x=> `${x.room_id}` == `${this.props.session.room_id}`)

    const roomName = room ? room.name : "";
    return roomName.replace("Mandalay Bay,","")
  },

  render: function() {
    const room = this.props.rooms.find(x=> `${x.room_id}` == `${this.props.session.room_id}`)
    return (
      <div id={`session-${this.props.session.id}`} className={`session-box ${this.props.session.current ? "session-box-current" : ""}`}>
        <div className="session-box-wrapper clearfix">
          <div className="session-color">
            <div className="session-color-box" style={{backgroundColor: room && this.props.session.current ? room.color||"#ffffff" : "#797E81"}}/>
          </div>
          <div className="session-time">
            {this.timeDisplay()}
          </div>
          <div className="session-title">
            {this.props.session.name}
          </div>
          <div className="session-room">
            {this.getRoomName()}
          </div>
        </div>
      </div>
    );
  }
});

export default Session
