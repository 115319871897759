import React from 'react';
import DarkskyWeather from './darksky_weather';
const FooterDateTime = ({ clock }) => {

  return (
    <div className="sg-kiosk-app-footer">
      <div className="sg-kiosk-app-footer-content">
        <div className="sg-kiosk-app-footer-datetime">
        {clock.format('dddd, MMMM D • h:mm a').replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')}
        </div>
        <div className="sg-kiosk-app-footer-weather">
          <DarkskyWeather />
          {/* Partly Cloudy  |  83°F / 44°C */}
        </div>
        <div className="sg-kiosk-app-footer-icon">
          <img src="/images/kiosk/layout/footer-icon.svg" />
        </div>
      </div>
    </div>
  )

};
export default FooterDateTime;
